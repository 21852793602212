<template>
  <div class="allTemplate">
    <div class="searchflex">
      <div class="searchLeft">
        <div class="searchInput">
          <el-input
            size="mini"
            v-model="search"
            @input="getSearch()"
            placeholder="请输入内容"
            suffix-icon="el-icon-search"
          ></el-input>
        </div>
      </div>
      <div class="searchRight">
        <img src="@/assets/image/add_new.png" class="addIcon" @click="add()" />
      </div>
    </div>
    <yh-table
      tableBorder
      :tableHeight="tableHeight"
      :tableLoading="tableLoading"
      :tableHead="tableHead"
      :tableData="tableData"
    >
      <template #tableRow="{ row, item }">
        <span>{{ row[item.prop] }}</span>
      </template>
      <el-table-column label="操作" width="300" align="center" fixed="right">
        <template slot-scope="scope">
          <yh-popover
            popoverTitle="分配人员"
            @savePopUp="personnel(scope.row)"
          ></yh-popover>
          <yh-popover
            popoverTitle="权限"
            @savePopUp="permissions(scope.row)"
          ></yh-popover>
          <yh-popover
            popoverTitle="修改"
            @savePopUp="edit(scope.row)"
          ></yh-popover>
          <yh-popover
            popoverOnly="1"
            popoverTitle="删除"
            @savePopUp="del(scope.row)"
          >
            确定删除该信息吗？
          </yh-popover>
        </template>
      </el-table-column>
    </yh-table>
    <div class="pages">
      <el-pagination
        :current-page="page"
        :total="total"
        :page-size="size"
        :page-sizes="[20, 30, 40, 50]"
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>

    <!-- 新增编辑 -->
    <yh-dialog :dialogObj="dialogObj">
      <yh-form
        ref="formName"
        labelWidth="110px"
        :formRule="formRule"
        :formHead="formHead"
        :formData="formData"
      ></yh-form>
      <template #footer>
        <el-button size="mini" type="primary" v-if="prepare" @click="submit()">
          保 存
        </el-button>
        <el-button size="mini" type="primary" v-else> 保 存 </el-button>
      </template>
    </yh-dialog>

    <!-- 分配人员 -->
    <yh-dialog :dialogObj="personnelObj">
      <el-row class="personnel">
        <el-col :span="10" class="businessStyle">
          <div class="title">未分配</div>
          <el-table
            size="mini"
            :height="275"
            :data="notEmployeesData"
            @selection-change="checkLeftAll"
          >
            <el-table-column type="selection" width="45"></el-table-column>
            <el-table-column prop="name" label="名称"></el-table-column>
          </el-table>
        </el-col>
        <el-col :span="4">
          <div class="textCenter">
            <div class="spacing">
              <i
                class="blockRight"
                @click="handelSelect"
                v-if="nowSelectLeftData.length ? true : false"
              ></i>
              <i class="noneRight" v-else></i>
            </div>
            <div class="spacing">
              <i
                class="blockLeft"
                @click="handleRemoveSelect"
                v-if="nowSelectRightData.length ? true : false"
              ></i>
              <i class="noneLeft" v-else></i>
            </div>
          </div>
        </el-col>
        <el-col :span="10" class="businessStyle">
          <div class="title">已分配</div>
          <el-table
            size="mini"
            :height="275"
            :data="employeesData"
            @selection-change="checkRightAll"
          >
            <el-table-column type="selection" width="45"></el-table-column>
            <el-table-column prop="name" label="名称"></el-table-column>
          </el-table>
        </el-col>
      </el-row>
      <template #footer>
        <el-button
          size="mini"
          type="primary"
          v-if="prepare"
          @click="personnelSubmit()"
        >
          保 存
        </el-button>
        <el-button size="mini" type="primary" v-else> 保 存 </el-button>
      </template>
    </yh-dialog>

    <!-- 权限 -->
    <yh-dialog :dialogObj="permissionsObj">
      <permissions ref="permissions" :functionId="personnelsId"></permissions>
      <template #footer>
        <el-button
          size="mini"
          type="primary"
          v-if="prepare"
          @click="permissionsSubmit()"
        >
          保 存
        </el-button>
        <el-button size="mini" type="primary" v-else> 保 存 </el-button>
      </template>
    </yh-dialog>
  </div>
</template>
<script>
import permissions from "../component/permissions";
export default {
  components: {
    permissions,
  },
  data() {
    return {
      search: "",
      // 表格
      tableLoading: false,
      tableHeight: "100px",
      tableHead: [
        {
          prop: "name",
          label: "名称",
          fixed: false,
          minWidth: "120",
        },
        {
          prop: "description",
          label: "说明",
          fixed: false,
          minWidth: "120",
        },
      ],
      tableData: [],
      page: 1,
      size: 20,
      total: 0,

      // 新增修改
      dialogObj: {
        dialogTitle: "新增",
        dialogOpen: false,
      },
      formRule: {
        name: [{ required: true, message: "请输入名称", trigger: "blur" }],
      },
      formHead: [
        {
          comp: "el-input",
          label: "名称",
          prop: "name",
          widthSize: 1,
        },
        {
          comp: "el-input",
          label: "说明",
          prop: "description",
          widthSize: 1,
        },
      ],
      formData: {
        id: undefined,
        name: undefined,
        description: undefined,
      },
      // 人员分配
      personnelsId: "",
      personnelObj: {
        dialogTitle: "人员分配",
        dialogOpen: false,
        dialogWidth: "750px",
      },
      allEmployeesData: [],
      notEmployeesData: [],
      employeesData: [],
      nowSelectLeftData: [],
      nowSelectRightData: [],
      // 权限分配
      permissionsObj: {
        dialogTitle: "权限分配",
        dialogOpen: false,
        dialogWidth: "1000px",
      },

      prepare: true,
    };
  },
  created() {
    this.getHeight();
    window.addEventListener("resize", this.getHeight);
  },
  mounted() {
    this.getData();
    this.employees();
  },
  methods: {
    // 表格
    getSearch() {
      this.page = 1;
      this.getData();
    },
    handleSizeChange(val) {
      this.page = 1;
      this.size = val;
      this.getData();
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getData();
    },
    getData() {
      this.tableLoading = true;
      this.$axios.get(
        "roles",
        {
          perPage: this.size,
          page: this.page,
          q: this.search,
        },
        (res) => {
          this.tableLoading = false;
          var status = res.status;
          if (status.toString().substring(0, 1) == 2) {
            this.tableData = res.data;
            this.total = Number(res.headers["x-total-count"]);
          } else {
            this.$message.error(res.data.message);
          }
        }
      );
    },
    // 新增
    add() {
      this.formData = {
        id: undefined,
        name: undefined,
        description: undefined,
      };
      this.dialogObj.dialogOpen = true;
      this.dialogObj.dialogTitle = "新增";
    },
    edit(row) {
      this.formData = JSON.parse(JSON.stringify(row));
      this.dialogObj.dialogOpen = true;
      this.dialogObj.dialogTitle = "修改";
    },
    submit() {
      this.$refs.formName.validate((valid) => {
        if (valid) {
          this.prepare = false;
          if (this.formData.id) {
            this.$axios.jsonPost(
              "rolesEdit",
              {
                id: this.formData.id,
                name: this.formData.name,
                description: this.formData.description,
              },
              (res) => {
                this.prepare = true;
                var status = res.status;
                if (status.toString().substring(0, 1) == 2) {
                  this.dialogObj.dialogOpen = false;
                  this.$message.success("修改成功");
                  this.resetForm();
                  this.getData();
                } else {
                  this.$message.error(res.data.message);
                }
              }
            );
          } else {
            this.$axios.jsonPost(
              "roles",
              {
                name: this.formData.name,
                description: this.formData.description,
              },
              (res) => {
                this.prepare = true;
                var status = res.status;
                if (status.toString().substring(0, 1) == 2) {
                  this.dialogObj.dialogOpen = false;
                  this.$message.success("添加成功");
                  this.resetForm();
                  this.getData();
                } else {
                  this.$message.error(res.data.message);
                }
              }
            );
          }
        } else {
          this.$message.error("请填写必填项");
        }
      });
    },
    resetForm() {
      if (this.$refs.formName) {
        this.$refs.formName.resetFields();
      }
    },
    del(row) {
      this.$axios.jsonPost("rolesDel", { id: row.id }, (res) => {
        var status = res.status;
        if (status.toString().substring(0, 1) == 2) {
          this.$message.success("删除成功");
          this.getData();
        } else {
          this.$message.error(res.data.message);
        }
      });
    },
    // 分配人员
    personnel(row) {
      this.personnelsId = row.id;

      this.$axios.get("rolesEmployees", { id: row.id }, (res) => {
        const status = res.status;
        if (status.toString().substring(0, 1) == 2) {
          this.employeesData = res.data;
          this.notEmployeesData = this.allEmployeesData.filter((item) => {
            return !this.employeesData.find((prop) => {
              return prop.id == item.id;
            });
          });
          this.personnelObj.dialogOpen = true;
        } else {
          this.$message.error(res.data.message);
        }
      });
    },
    checkLeftAll(val) {
      this.nowSelectLeftData = val;
    },
    checkRightAll(val) {
      this.nowSelectRightData = val;
    },
    handelSelect() {
      // 选中
      this.employeesData = this.handleConcatArr(
        this.employeesData,
        this.nowSelectLeftData
      );
      this.handleRemoveTabList(this.nowSelectLeftData, this.notEmployeesData);
      this.nowSelectLeftData = [];
    },
    handleRemoveSelect() {
      // 取消
      this.notEmployeesData = this.handleConcatArr(
        this.notEmployeesData,
        this.nowSelectRightData
      );
      this.handleRemoveTabList(this.nowSelectRightData, this.employeesData);
      this.nowSelectRightData = [];
    },
    handleConcatArr(selectArr, nowSelectData) {
      let arr = [];
      arr = arr.concat(selectArr, nowSelectData);
      return arr;
    },
    handleRemoveTabList(isNeedArr, originalArr) {
      if (isNeedArr.length && originalArr.length) {
        for (let i = 0; i < isNeedArr.length; i++) {
          for (let k = 0; k < originalArr.length; k++) {
            if (isNeedArr[i]["name"] === originalArr[k]["name"]) {
              originalArr.splice(k, 1);
            }
          }
        }
      }
    },
    personnelSubmit() {
      let employeeIds = [];
      for (let i = 0; i < this.employeesData.length; i++) {
        employeeIds.push(this.employeesData[i].id);
      }
      this.prepare = false;
      this.$axios.jsonPost(
        "rolesEmployeesEdit",
        {
          id: this.personnelsId,
          employeeIds: employeeIds,
        },
        (res) => {
          this.prepare = true;
          var status = res.status;
          if (status.toString().substring(0, 1) == 2) {
            this.personnelObj.dialogOpen = false;
            this.$message.success("分配成功");
            this.getData();
          } else {
            this.$message.error(res.data.message);
          }
        }
      );
    },
    // 权限分配
    permissions(row) {
      this.personnelsId = row.id;
      this.permissionsObj.dialogOpen = true;
      this.$nextTick(() => {
        this.$refs.permissions.getProducts();
        this.$refs.permissions.getMenus();
      });
    },
    permissionsSubmit() {
      this.$nextTick(() => {
        this.$refs.permissions.functionSubmit();
        this.permissionsObj.dialogOpen = false;
      });
    },
    employees() {
      this.$axios.get("employees", {}, (res) => {
        const status = res.status;
        if (status.toString().substring(0, 1) == 2) {
          this.allEmployeesData = res.data;
        } else {
          this.$message.error(res.data.message);
        }
      });
    },
    getHeight() {
      this.tableHeight = window.innerHeight - (50 + 40 + 60 + 30 + 45) + "px";
    },
  },
};
</script>
<style lang="scss" scoped>
.pages {
  text-align: right;
  padding: 5px 0;
  border: 1px solid #ddd;
}
.personnel {
  display: flex;
  align-items: center;
  .businessStyle {
    border: 1px solid #dcdcdc;
    border-radius: 5px;
    .title {
      width: 100%;
      height: 35px;
      line-height: 35px;
      padding-left: 10px;
      box-sizing: border-box;
      border-radius: 5px 5px 0px 0px;
      border-bottom: 1px solid #dcdcdc;
      color: #7f92a7;
      background-color: #f6f7fc;
    }
  }
  .textCenter {
    text-align: center;
    .spacing {
      line-height: 35px;
    }
  }
  .blockRight {
    display: inline-block;
    width: 24px;
    height: 16px;
    background: url("../../assets/image/background.png") -751px -142px no-repeat;
    margin-right: 5px;
    cursor: pointer;
  }
  .noneRight {
    display: inline-block;
    width: 24px;
    height: 16px;
    background: url("../../assets/image/background.png") -811px -142px no-repeat;
    cursor: not-allowed;
  }
  .blockLeft {
    display: inline-block;
    width: 24px;
    height: 16px;
    background: url("../../assets/image/background.png") -784px -142px no-repeat;
    margin-right: 5px;
    cursor: pointer;
  }
  .noneLeft {
    display: inline-block;
    width: 24px;
    height: 16px;
    background: url("../../assets/image/background.png") -840px -142px no-repeat;
    cursor: not-allowed;
  }
}
</style>
