<template>
  <div class="permission">
    <div class="products">
      <div class="title">应用</div>
      <div class="productList">
        <div
          class="productLi"
          :class="index == productsIndex ? 'productLiHover' : ''"
          v-for="(item, index) in productsList"
          :key="index"
          @click="getAllMenus(index)"
        >
          {{ item.name }}
        </div>
      </div>
    </div>
    <div class="table">
      <el-table
        height="420px"
        border
        size="medium"
        row-key="id"
        default-expand-all
        :cell-style="{ 'text-align': 'left' }"
        :header-cell-style="{ 'text-align': 'center' }"
        :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
        v-if="productsList[productsIndex]"
        :data="productsList[productsIndex].allMenusData"
      >
        <el-table-column label="菜单" width="200">
          <template slot-scope="scope">
            <div class="centerCheck">
              <el-checkbox
                :value="scope.row.permission == '1' ? true : false"
                @change="demo($event, scope.row)"
              >
                {{ scope.row.name }}
              </el-checkbox>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="功能">
          <template slot-scope="scope">
            <div class="centerCheck">
              <el-checkbox
                :value="item.permission == '1' ? true : false"
                v-for="(item, index) in scope.row.operations"
                :key="index"
                @change="demoChik($event, scope.row, index)"
              >
                {{ item.name }}
              </el-checkbox>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>
<script>
import { listRecursion } from "@/utils/convert";
export default {
  props: ["functionId"],
  data() {
    return {
      company: JSON.parse(sessionStorage.getItem("userinfo")),
      productsIndex: 0,
      productsList: [],
      menusData: [],
      roleIds: [],
    };
  },
  methods: {
    getProducts() {
      this.$axios.get(
        "tenantsProducts",
        { id: this.company.tenants[0].id },
        (res) => {
          var status = res.status;
          if (status.toString().substring(0, 1) == 2) {
            this.productsList = res.data;
            this.getAllMenus(0);
          } else {
            this.$message.error(res.data.message);
          }
        }
      );
    },
    getAllMenus(index) {
      this.productsIndex = index;
      if (!this.productsList[index].allMenusData) {
        this.$axios.get(
          "tenantsMenus",
          { id: this.company.tenants[0].id },
          (res) => {
            var status = res.status;
            if (status.toString().substring(0, 1) == 2) {
              for (let z = 0; z < res.data.length; z++) {
                for (let j = 0; j < this.menusData.length; j++) {
                  if (res.data[z].id == this.menusData[j].id) {
                    this.$set(res.data[z], "permission", "1");
                  }
                }
              }
              for (let i = 0; i < this.productsList.length; i++) {
                this.$set(this.productsList[i], "allMenusData", []);
                for (let s = 0; s < res.data.length; s++) {
                  if (this.productsList[i].id == res.data[s].productId) {
                    this.productsList[i].allMenusData.push(res.data[s]);
                  }
                }
              }
              for (let i = 0; i < this.productsList.length; i++) {
                let list = this.filterType(this.productsList[i].allMenusData);
                this.productsList[i].allMenusData = listRecursion(list);
              }
            } else {
              this.$message.error(res.data.message);
            }
          }
        );
      }
    },
    filterType(list) {
      let menus = [];
      let btn = [];
      for (let i = 0; i < list.length; i++) {
        if (!list[i].permission) {
          this.$set(list[i], "permission", "");
        }
        if (list[i].type == "MENU") {
          menus.push(list[i]);
        } else {
          btn.push(list[i]);
        }
      }
      for (let j = 0; j < menus.length; j++) {
        this.$set(menus[j], "operations", []);
        for (let s = 0; s < btn.length; s++) {
          if (menus[j].id == btn[s].parentId) {
            menus[j].operations.push(btn[s]);
          }
        }
      }
      return menus;
    },
    getMenus() {
      this.$axios.get("rolesMenus", { id: this.functionId }, (res) => {
        var status = res.status;
        if (status.toString().substring(0, 1) == 2) {
          this.menusData = res.data;
        } else {
          this.$message.error(res.data.message);
        }
      });
    },
    demo(eve, data) {
      if (data.children) {
        this.getMenu(eve, data);
      } else {
        data.permission = JSON.stringify(Number(eve));
        if (data.operations && data.operations.length > 0) {
          for (let i = 0; i < data.operations.length; i++) {
            data.operations[i].permission = JSON.stringify(Number(eve));
          }
        }
      }
    },
    // 复选菜单
    getMenu(eve, data) {
      data.permission = JSON.stringify(Number(eve));
      data.children.forEach((el) => {
        el.permission = JSON.stringify(Number(eve));
        if (el.operations && el.operations.length > 0) {
          el.operations.forEach((evl) => {
            evl.permission = JSON.stringify(Number(eve));
          });
        }
        if (el.children) {
          this.getMenu(eve, el);
        }
      });
    },
    demoChik(eve, row, index) {
      row.operations[index].permission = JSON.stringify(Number(eve));
      let isOperations = row.operations.some((el) => {
        if (el.permission == "1") {
          return true;
        }
      });
      if (isOperations == true) {
        row.permission = JSON.stringify(Number(1));
      }
    },
    functionSubmit() {
      let list = [];
      for (let i = 0; i < this.productsList.length; i++) {
        if (this.productsList[i].allMenusData) {
          list = list.concat(this.productsList[i].allMenusData);
        }
      }

      let data = JSON.parse(JSON.stringify(list));
      this.roleIds = [];
      for (let j = 0; j < data.length; j++) {
        if (data[j].children) {
          this.forData(data[j]);
        }
        this.roleIds.push(data[j]);
      }

      let menuIds = [];
      for (let s = 0; s < this.roleIds.length; s++) {
        if (this.roleIds[s].permission == "1") {
          menuIds.push(this.roleIds[s].id);
        }
        if (this.roleIds[s].operations.length > 0) {
          for (let z = 0; z < this.roleIds[s].operations.length; z++) {
            if (this.roleIds[s].operations[z].permission == "1") {
              menuIds.push(this.roleIds[s].operations[z].id);
            }
          }
        }
      }
      this.$axios.restJsonPost(
        "rolesMenusEdit",
        {
          id: this.functionId,
        },
        { menuIds: menuIds },
        (res) => {
          var status = res.status;
          if (status.toString().substring(0, 1) == 2) {
            this.$message.success("修改成功");
          } else {
            this.$message.error(res.data.message);
          }
        }
      );
    },
    forData(data) {
      for (let s = 0; s < data.children.length; s++) {
        if (data.children[s].children) {
          this.forData(data.children[s]);
        }
        this.roleIds.push(data.children[s]);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.permission {
  display: flex;
  justify-content: space-between;
  .products {
    width: 180px;
    border: 1px solid #ebeef5;
    border-radius: 4px;
    overflow-y: auto;
    .title {
      width: 100%;
      height: 44px;
      line-height: 44px;
      padding-left: 10px;
      box-sizing: border-box;
      border-radius: 5px 5px 0px 0px;
      border-bottom: 1px solid #ebeef5;
      color: #909399;
      font-weight: 700;
      text-align: center;
    }
    .productList {
      .productLi {
        height: 44px;
        line-height: 44px;
        padding-left: 10px;
        box-sizing: border-box;
        border-bottom: 1px solid #ebeef5;
        color: #606266;
        cursor: pointer;
      }
      .productLiHover {
        background-color: #f6f7fc;
      }
    }
  }
  .table {
    width: calc(100% - 190px);
    .centerCheck {
      display: inline-block;
    }
  }
}
</style>
